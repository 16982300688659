import { ENV } from "../../../../src/app/interfaces/interfaces";

export const environment:ENV = {
    production: false,
    apiUrl: 'https://orientemembresias.test.solunes.com/',
    type: 'oriente-petrolero',
    title: 'Oriente Petrolero',
    subtitle: 'Abonos',
    description: `"Oriente Petrolero - Abonos" es un sitio en el que puedes administrar tus abonos.`,
    keywords: 'club, oriente petrolero, oriente, deportivo, abonos',
    yearProyect: '2024',
    imgLogo: '/assets/img/logo.png',
    imgLogoSVG: '/assets/img/logosvg.png',
    imgLogoBig: '/assets/img/logo-big.png',
    imgLogoBanner:'assets/img/banner.jpg',
    templateType: 'template-3',
    theme: 'light',
    changeTheme: false,

    dashboard: false,
    disclaimer: false,
    header: true,
    sidebar: true,
    footer: true,
    preFooter: false,

    cart: false,

    authType: 'auth-3',
    authGoogle: false,
    authFacebook: false,
    authApple: false,
    authGithub: false,
    textToLogin: '¿Ya tienes una cuenta?',
    textToRegister: '¿Todavía no eres miembro?',

    authRedirect: 'shop',

    processCartType: 'type-2',
    processCartTitle: 'Conviértete en Abonado Gualdinegro',

    multiplePayments: true,
    multipleProfiles: true,

    loaderType: 2,
    loaderDefaultText: '',

    //* Firebase
    firebase: {
        "projectId":"clubbolivar-membresias",
        "appId":"1:924925915724:web:9f327aa12495b5d7c9fae9",
        "storageBucket":"clubbolivar-membresias.appspot.com",
        "apiKey":"AIzaSyBn7I3w7JFAJcP-AVJNWhht25jhyGUgN2M",
        "authDomain":"clubbolivar-membresias.firebaseapp.com",
        "messagingSenderId":"924925915724",
        "measurementId":"G-J741BLEVFD",
    },
    
    keyEncript: '12345678912345678',
    ivEncript: '12345678912345678',
};
